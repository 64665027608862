@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap");

#projects {
  background-color: rgb(230, 216, 220);
  padding: 60px 0px 50px 0px;
}

#avatar {
  margin: 40% 0px 10px 0px;
  pointer-events: none;
}

.project-text {
  padding: 5% 15% 25px 15%;
  color: #5d4954;
}

.gameseta {
  background-color: #495483;
  padding: 75px 0px 50px 0px;
}

.tradefinder {
  background-color: #497283;
  padding: 75px 0px 50px 0px;
}

.scheduler {
  background-color: #6e475d;
  padding: 75px 0px 50px 0px;
}

.resin {
  background-color: #5d436e;
  padding: 75px 0px 50px 0px;
}

.tweeter {
  background-color: #6f9e88;
  padding: 75px 0px 50px 0px;
}

.stacks {
  color: #e4e4e4;
  font-style: italic;
  padding: 10px 0px 4% 0px;
}

.project-desc {
  color: #e4e4e4;
  font-style: italic;
  padding: 5% 15% 25px 15%;
}

.project-images {
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px 15px 7px 7px;
  box-shadow: 0 4px 40px 0 rgba(54, 54, 54, 0.5),
    0 4px 40px 0 rgba(34, 34, 34, 0.2);
}

#project-titles {
  color: #e4e4e4;
  font-family: "Josefin Sans", sans-serif;
  letter-spacing: 3px;
}
