@import url(https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  background-color: #eeebeb;
  margin: 0px;
	padding: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: #e4e4e4;
  /* background-image: url("https://www.transparenttextures.com/patterns/bright-squares.png"); */
}

.App-logo {
  color: #e2e2e2;
	text-transform: uppercase;
	letter-spacing: 5px;
  font-size: 14px;
}

/* .nav {
	display: flex; 
  justify-content: flex-start;
	align-items: center; 
	min-height: 8vh;
	background-color: #5d4954;
	font-family: 'Poppins', sans-serif;
} */

.nav-links{
  display: flex;
  justify-content: space-between;
  list-style: none;
  scroll-behavior: smooth;
}

.nav-links a{
  color: #e2e2e2;
  text-decoration: none; 
  letter-spacing: 3px;
  font-weight: bold;
  font-size: 13px;
  scroll-behavior: smooth;
}

.nav-links a:hover{
  color: #d4bcc8;
  font-size: 16px; 
}

@media screen and (max-width: 1024px) and (min-width: 769px){
	body{
		overflow-x: hidden; 
  }
  
  .App-logo {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px){
	body{
		overflow-x: hidden; 
  }
  
  .App-logo {
    font-size: 8px;
  } 
}





.footer {
  padding-top: 15px;
  padding-bottom: 15px;
  bottom: 0;
  background-color: #5d4954;
  color: #e4e4e4;
  font-family: poppins;
  height: 180px;
}

.footer .footer-content h2 {
  font-family: poppins;
  text-transform: uppercase;
  color: #e2e2e2;
  font-size: 16px;
  margin-bottom: 8px;
  margin-right: 5px;
}

.footer .footer-about h2 span {
  color: #e2e2e2;
}

.footer-about p {
  display: block;
  font-size: 12px;
  margin-bottom: 30px;
  margin-top: 13px;
  width: 80%;
}

.footer .footer-about .contact span {
  display: block;
  font-size: 13px;
  margin-bottom: 8px;
}

.footer .footer-about .contact span:hover {
  color: #d3b0c2;
}

.footer .footer-about .contact a:hover {
  color: #d3b0c2;
}

.footer .footer-about .socials a {
  border: 1px transparent;
  color: #e2e2e2;
  width: 30px;
  height: 30px;
  padding: 3px;
  text-align: center;
  display: inline-block;
  font-size: 1.5em;
  border-radius: 3px;
}

.footer .footer-about .socials a:hover {
  color: #d3b0c2;
}

.footer .footer-links a {
  display: block;
  margin-bottom: 10px;
  list-style-type: none;
  font-size: 13px;
  color: #e2e2e2;
  text-decoration: none;
}

.footer .footer-links a:hover {
  color: #d3b0c2;
  margin-left: 15px;
  transition: all 0.5s;
}

.footer .contact-input {
  width: 90%;
  margin-bottom: 10px;
  line-height: 1.5em;
  border-radius: 5px;
  border: 1px transparent;
}

.footer .contact-input.selected {
  background-color: #dfc8d4;
  outline: none;
}

.footer .contact-input:hover {
  outline: none;
}

.send-btn {
  width: 25%;
  color: #5d4954;
}
.send-btn:hover {
  background-color: #dfc8d4 !important;
}

.envelope-icon {
  margin-right: 5px;
  color: #5d4954;
}

.footer-bottom {
  bottom: 0;
  background-color: #7a616e;
  color: #e2e2e2;
  height: 30px;
  width: 100%;
  text-align: center;
  position: relative;
  left: 0px;
  padding-top: 7px;
  margin-top: 40px;
  font-size: 10px;
}

.email {
  text-decoration: none;
  color: #e4e4e4;
}

@media screen and (max-width: 900px) {
  .footer {
    height: 200px;
  }
  .footer .footer-content h2 {
    font-size: 14px;
  }
}

@media screen and (max-width: 772px) {
  .footer {
    height: 220px;
  }

  .footer-about p {
    font-size: 11px;
    margin-bottom: 25px;
    margin-top: 12px;
  }
}

@media screen and (max-width: 600px) {
  .footer {
    height: 250px;
  }

  .footer .footer-content h2 {
    font-size: 13px;
    margin-bottom: 6px;
    margin-right: 5px;
  }

  .footer-about p {
    font-size: 10px;
    margin-bottom: 20px;
    margin-top: 11px;
  }
}

body {
  overflow-x: hidden;
}

#homepage-image {
  width: 100%;
  margin-top: 9.5vh;
  border: 1px solid #ececec;
  box-shadow: 2px 2px #ececec;
}
#about #top {
  background-color: #ececec;
  padding: 60px 0px;
}

.page-container {
  padding: 7px 0px 20px 0px;
  margin-bottom: 3%;
}

.title {
  position: absolute;
  top: 22%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1% 0%;
  color: #5d4954;
  font-family: "Kaushan Script";
  font-style: italic;
  font-weight: bolder;
  letter-spacing: 1.5px;
}

#profile-image {
  pointer-events: none;
}

.body-content {
  min-height: 100vh;
}

#intro {
  padding-bottom: 50px;
}

.avatar {
  width: 100%;
  height: 100%;
}

#about-title {
  padding: 1% 0%;
  color: #5d4954;
  font-family: poppins;
  font-style: italic;
  letter-spacing: 3px;
}

.about-intro {
  color: #5d4954;
  font-family: poppins;
  font-style: italic;
}

.about-me {
  padding: 10% 5%;
  color: #5d4954;
}

@media screen and (min-width: 900px) {
  .fade-in {
    opacity: 0;
    transition: opacity 1s ease-in;
  }

  .fade-in.appear {
    opacity: 1;
  }

  .avatar {
    grid-area: avatar;
    padding: 8em 0;
  }

  .about-me {
    grid-area: about-me;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-areas: 'avatar about-me';
    padding: 2em 4em;
    grid-gap: 7em;
    align-items: center;
    grid-template-columns: minmax(1em, 1fr) repeat(2, 1em, 1fr);
  }

  .avatar {
    grid-area: avatar;
    padding: 8em 0;
  }
  
  .about-me {
    grid-area: about-me;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-areas: 'avatar about-me';
    padding: 2em 4em;
    grid-gap: 7em;
    align-items: center;
    grid-template-columns: minmax(1em, 1fr) repeat(2, 1em, 1fr);
  }

  .from-left {
    grid-column:  2 / 3;
    transform: translateX(-100%);
  }

  .from-right {
    grid-column:  3 / 4;
    transform: translateX(75%);
  }

  .from-left,
  .from-right {
    transition: opacity 0.8s ease-in, 
    transform 1s ease-in;
    opacity: 0;
  }

  .from-left.appear,
  .from-right.appear {
    transform: translateX(0);
    opacity: 1;
  }
}

@media screen and (max-width: 962px) and (min-width: 600px) {

  .title {
    top: 20%;
  }
  
  .avatar {
    grid-area: avatar;
    padding: 3.5em 43% 1em 43%;
  }

  .about-me {
    padding-top: 5%;
    margin: 0% 5%;
  }
  
  #about-title {
    padding: 0;
  }
  
  .grid-container {
    display: grid;
    width: 100%;
    grid-template-areas: 'avatar''about-me';
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {

  .title {
    top: 16%;
  }  

  #intro {
    padding-bottom: 30px;
  }

  .avatar {
    grid-area: avatar;
    padding: 3em 37% 1em 37%;
  }
  
  .about-me {
    grid-area: about-me;
    text-align: center;
    margin: 0% 5%;
  }
  
  .grid-container {
    display: grid;
    width: 100%;
    grid-template-areas: 'avatar''about-me';
    justify-content: center;
  }
}

#projects {
  background-color: rgb(230, 216, 220);
  padding: 60px 0px 50px 0px;
}

#avatar {
  margin: 40% 0px 10px 0px;
  pointer-events: none;
}

.project-text {
  padding: 5% 15% 25px 15%;
  color: #5d4954;
}

.gameseta {
  background-color: #495483;
  padding: 75px 0px 50px 0px;
}

.tradefinder {
  background-color: #497283;
  padding: 75px 0px 50px 0px;
}

.scheduler {
  background-color: #6e475d;
  padding: 75px 0px 50px 0px;
}

.resin {
  background-color: #5d436e;
  padding: 75px 0px 50px 0px;
}

.tweeter {
  background-color: #6f9e88;
  padding: 75px 0px 50px 0px;
}

.stacks {
  color: #e4e4e4;
  font-style: italic;
  padding: 10px 0px 4% 0px;
}

.project-desc {
  color: #e4e4e4;
  font-style: italic;
  padding: 5% 15% 25px 15%;
}

.project-images {
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px 15px 7px 7px;
  box-shadow: 0 4px 40px 0 rgba(54, 54, 54, 0.5),
    0 4px 40px 0 rgba(34, 34, 34, 0.2);
}

#project-titles {
  color: #e4e4e4;
  font-family: "Josefin Sans", sans-serif;
  letter-spacing: 3px;
}

