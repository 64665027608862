.footer {
  padding-top: 15px;
  padding-bottom: 15px;
  bottom: 0;
  background-color: #5d4954;
  color: #e4e4e4;
  font-family: poppins;
  height: 180px;
}

.footer .footer-content h2 {
  font-family: poppins;
  text-transform: uppercase;
  color: #e2e2e2;
  font-size: 16px;
  margin-bottom: 8px;
  margin-right: 5px;
}

.footer .footer-about h2 span {
  color: #e2e2e2;
}

.footer-about p {
  display: block;
  font-size: 12px;
  margin-bottom: 30px;
  margin-top: 13px;
  width: 80%;
}

.footer .footer-about .contact span {
  display: block;
  font-size: 13px;
  margin-bottom: 8px;
}

.footer .footer-about .contact span:hover {
  color: #d3b0c2;
}

.footer .footer-about .contact a:hover {
  color: #d3b0c2;
}

.footer .footer-about .socials a {
  border: 1px transparent;
  color: #e2e2e2;
  width: 30px;
  height: 30px;
  padding: 3px;
  text-align: center;
  display: inline-block;
  font-size: 1.5em;
  border-radius: 3px;
}

.footer .footer-about .socials a:hover {
  color: #d3b0c2;
}

.footer .footer-links a {
  display: block;
  margin-bottom: 10px;
  list-style-type: none;
  font-size: 13px;
  color: #e2e2e2;
  text-decoration: none;
}

.footer .footer-links a:hover {
  color: #d3b0c2;
  margin-left: 15px;
  transition: all 0.5s;
}

.footer .contact-input {
  width: 90%;
  margin-bottom: 10px;
  line-height: 1.5em;
  border-radius: 5px;
  border: 1px transparent;
}

.footer .contact-input.selected {
  background-color: #dfc8d4;
  outline: none;
}

.footer .contact-input:hover {
  outline: none;
}

.send-btn {
  width: 25%;
  color: #5d4954;
}
.send-btn:hover {
  background-color: #dfc8d4 !important;
}

.envelope-icon {
  margin-right: 5px;
  color: #5d4954;
}

.footer-bottom {
  bottom: 0;
  background-color: #7a616e;
  color: #e2e2e2;
  height: 30px;
  width: 100%;
  text-align: center;
  position: relative;
  left: 0px;
  padding-top: 7px;
  margin-top: 40px;
  font-size: 10px;
}

.email {
  text-decoration: none;
  color: #e4e4e4;
}

@media screen and (max-width: 900px) {
  .footer {
    height: 200px;
  }
  .footer .footer-content h2 {
    font-size: 14px;
  }
}

@media screen and (max-width: 772px) {
  .footer {
    height: 220px;
  }

  .footer-about p {
    font-size: 11px;
    margin-bottom: 25px;
    margin-top: 12px;
  }
}

@media screen and (max-width: 600px) {
  .footer {
    height: 250px;
  }

  .footer .footer-content h2 {
    font-size: 13px;
    margin-bottom: 6px;
    margin-right: 5px;
  }

  .footer-about p {
    font-size: 10px;
    margin-bottom: 20px;
    margin-top: 11px;
  }
}
