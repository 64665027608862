* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  background-color: #eeebeb;
  margin: 0px;
	padding: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  background-color: #e4e4e4;
  /* background-image: url("https://www.transparenttextures.com/patterns/bright-squares.png"); */
}

.App-logo {
  color: #e2e2e2;
	text-transform: uppercase;
	letter-spacing: 5px;
  font-size: 14px;
}

/* .nav {
	display: flex; 
  justify-content: flex-start;
	align-items: center; 
	min-height: 8vh;
	background-color: #5d4954;
	font-family: 'Poppins', sans-serif;
} */

.nav-links{
  display: flex;
  justify-content: space-between;
  list-style: none;
  scroll-behavior: smooth;
}

.nav-links a{
  color: #e2e2e2;
  text-decoration: none; 
  letter-spacing: 3px;
  font-weight: bold;
  font-size: 13px;
  scroll-behavior: smooth;
}

.nav-links a:hover{
  color: #d4bcc8;
  font-size: 16px; 
}

@media screen and (max-width: 1024px) and (min-width: 769px){
	body{
		overflow-x: hidden; 
  }
  
  .App-logo {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px){
	body{
		overflow-x: hidden; 
  }
  
  .App-logo {
    font-size: 8px;
  } 
}




