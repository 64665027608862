@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");

body {
  overflow-x: hidden;
}

#homepage-image {
  width: 100%;
  margin-top: 9.5vh;
  border: 1px solid #ececec;
  box-shadow: 2px 2px #ececec;
}
#about #top {
  background-color: #ececec;
  padding: 60px 0px;
}

.page-container {
  padding: 7px 0px 20px 0px;
  margin-bottom: 3%;
}

.title {
  position: absolute;
  top: 22%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1% 0%;
  color: #5d4954;
  font-family: "Kaushan Script";
  font-style: italic;
  font-weight: bolder;
  letter-spacing: 1.5px;
}

#profile-image {
  pointer-events: none;
}

.body-content {
  min-height: 100vh;
}

#intro {
  padding-bottom: 50px;
}

.avatar {
  width: 100%;
  height: 100%;
}

#about-title {
  padding: 1% 0%;
  color: #5d4954;
  font-family: poppins;
  font-style: italic;
  letter-spacing: 3px;
}

.about-intro {
  color: #5d4954;
  font-family: poppins;
  font-style: italic;
}

.about-me {
  padding: 10% 5%;
  color: #5d4954;
}

@media screen and (min-width: 900px) {
  .fade-in {
    opacity: 0;
    transition: opacity 1s ease-in;
  }

  .fade-in.appear {
    opacity: 1;
  }

  .avatar {
    grid-area: avatar;
    padding: 8em 0;
  }

  .about-me {
    grid-area: about-me;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-areas: 'avatar about-me';
    padding: 2em 4em;
    grid-gap: 7em;
    align-items: center;
    grid-template-columns: minmax(1em, 1fr) repeat(2, 1em, 1fr);
  }

  .avatar {
    grid-area: avatar;
    padding: 8em 0;
  }
  
  .about-me {
    grid-area: about-me;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-areas: 'avatar about-me';
    padding: 2em 4em;
    grid-gap: 7em;
    align-items: center;
    grid-template-columns: minmax(1em, 1fr) repeat(2, 1em, 1fr);
  }

  .from-left {
    grid-column:  2 / 3;
    transform: translateX(-100%);
  }

  .from-right {
    grid-column:  3 / 4;
    transform: translateX(75%);
  }

  .from-left,
  .from-right {
    transition: opacity 0.8s ease-in, 
    transform 1s ease-in;
    opacity: 0;
  }

  .from-left.appear,
  .from-right.appear {
    transform: translateX(0);
    opacity: 1;
  }
}

@media screen and (max-width: 962px) and (min-width: 600px) {

  .title {
    top: 20%;
  }
  
  .avatar {
    grid-area: avatar;
    padding: 3.5em 43% 1em 43%;
  }

  .about-me {
    padding-top: 5%;
    margin: 0% 5%;
  }
  
  #about-title {
    padding: 0;
  }
  
  .grid-container {
    display: grid;
    width: 100%;
    grid-template-areas: 'avatar''about-me';
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {

  .title {
    top: 16%;
  }  

  #intro {
    padding-bottom: 30px;
  }

  .avatar {
    grid-area: avatar;
    padding: 3em 37% 1em 37%;
  }
  
  .about-me {
    grid-area: about-me;
    text-align: center;
    margin: 0% 5%;
  }
  
  .grid-container {
    display: grid;
    width: 100%;
    grid-template-areas: 'avatar''about-me';
    justify-content: center;
  }
}
